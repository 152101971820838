body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}

/* Home */

.homeBg {
  background-image: url("../public/img/home/homeDesk.png");
  background-size:cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;

} 

.componentBg {
  background-image: url("../public/img/component/fondComponent.png");
  background-size:cover;
  background-repeat: no-repeat;
 /* height: 100vh;  Si besoin (taille de card) */
  /* width: 100%; */

} 

.pageError {
  /* height: 100vh; */
  width: 100%;
}

.linkGo{
  position: absolute;
  left: 85px;
  bottom: 45px;
}

.description{
  width: 263px;
}

/* Navbar */


.logoMarseille{
  margin-top: 20%;
}

.transport:hover, .hebergement:hover, .restaurant:hover, .sport:hover {
  letter-spacing: 6px; 
  transition: all 1s ease; 
}




.imgError {
  height: 425px;
  width: 312px;
}

/* Presentation */
.pres {
   background-color: #00004d;
   width: 100%;
   color: #FFFFFF;
}
/* Footer */
.footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #707070;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.footer a {
  flex-basis: 30%;
  text-align: center;
  padding: 10px;
}

.footer img {
  height: 70px;
  object-fit: contain;
}

.smaller {
  height: 40px !important;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

/* media */
@media screen and (max-width: 1019px) {
  .homeBg {
    background-image: url("../public/img/home/homeTab.png");
    background-position: 42% 0%;
  
  } 
  
}
@media screen and (max-width: 555px) {
  .homeBg {
    background-image: url("../public/img/home/homeTel.png");
 
  
  } 

  .logo {
    width:13rem;
  }
  
}